<template>
  <div class="div-clas mb-5" style="margin-top:-5rem !important;">
      <div class="bg-des-p">
        <div class="container-fluid margin-text-titulo-des-p">
          <div class="row">
            <img class="img-header-des-p-movil" style="margin-top:7rem !important;" src="../assets/Nosotros/icono_principal.png" alt="">
            <div class="col-md-6 offset-md-1 mt-5 " >
              <h1 class="text-titulo-header-des-p animate__animated  animate__bounceInDown animate__fast "><b>DESARROLLO DE PLATAFORMAS</b></h1>
              <div class="tamaño-div-des-p"><p class="text-header-des-p " data-aos="zoom-in" data-aos-easing="linear">Somos especialistas en desarrollo de aplicaciones web (web apps). Te compartimos un ejemplo de lo que podemos lograr.</p></div>
              <p class="text-des-p" data-aos="zoom-in" data-aos-delay="100"  data-aos-easing="linear">*Servicios no disponible en modalidad individual. Se trabaja exclusivamente en cualquiera de nuestros paquetes.</p>
            
            </div>
            <div class="col-md-5">
              <img class="img-header-des-p mb-5" style="margin-top:5rem !important;"  data-aos="zoom-out-down" src="../assets/Nosotros/icono_principal.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row" data-aos="fade-up"> 
          <img class="img-inmobina-movil img-fluid" src="../assets/img-techsoft/inmobina.png" alt="">
          <div class="col-md-7 div-vendemas text-align-l-vendemas">
              <h1 class="text-titulo-vendemas" data-aos="zoom-in"><b> Inmobina </b></h1>
              <p class="p-vendemas mt-4" data-aos="zoom-in" data-aos-delay="200"  data-aos-easing="linear">Es una plataforma digital enfocada en la formación en bienes raices mediante la venta de cursos online. La empresa está dirigida a personas que busquen incrementar sus ingresos exponencialmente o empezar desde cero en la industria inmobiliaria.</p>
              <p class="p-vendemas" data-aos="zoom-in" data-aos-delay="200"  data-aos-easing="linear">Desarrollamos su plataforma con base en las necesidades de la empresa y del usuario final.</p><br>
              <p class="p-vendemas " data-aos="zoom-in"  data-aos-easing="linear">Te compartimos algunos de sus atributos:</p><br>
              <b-icon class="checkbox" icon="check-square" scale="2" variant="primary"></b-icon>
              <p class="p-vendemas2 "  data-aos="fade-right">Venta de cursos online de bienes raíces</p><br>
              <b-icon class="checkbox" icon="check-square" scale="2" variant="primary"></b-icon>
              <p class="p-vendemas2 " data-aos="fade-right" >Conexión a pagos con paypal y Stripe</p><br>
              <b-icon class="checkbox" icon="check-square" scale="2" variant="primary"></b-icon>
              <p class="p-vendemas2 " data-aos="fade-right">Acceso a reportes de ventas</p><br>
              <b-icon class="checkbox" icon="check-square" scale="2" variant="primary"></b-icon>
              <p class="p-vendemas2 " data-aos="fade-right">Visualización de información de usuarios</p><br>
              <b-icon class="checkbox" icon="check-square" scale="2" variant="primary"></b-icon>
              <p class="p-vendemas2 " data-aos="fade-right">Multiusuario</p><br>
              <b-icon class="checkbox" icon="check-square" scale="2" variant="primary"></b-icon>
              <p class="p-vendemas2 mb-5 " data-aos="fade-right">Visualización de información de usuarios</p>
              
          </div>
          <div class="col-md-4 ">
            <div class=""><img class="img-inmobina img-fluid" src="../assets/img-techsoft/inmobina.png" alt=""></div>
          </div><br>
        </div>
      </div>
      <div>
        <a class="btn btn-outline-primary btn-lg pill mt-4 shadow"  href="mailto:ventas@techsoftconsulting.mx">
        <span>
          <span class="h6 mr-2 font-weight-bold">Contactenos</span>
          <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
        </span>
      </a>
      </div>
  </div> 
</template>

<script>
export default {

}
</script>

<style>
.pill {
  
  
  padding: 10px 20px;
  text-align: center;
  
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 30px;
}
.img-inmobina-movil{
  display: none;
}

.img-header-des-p-movil{
  display: none;
}
.img-header-des-p{
  display: inline;
}
.f-creweb{
  height: 500px;
  margin-bottom: 20%;
  margin-top: -1%;
}

.bg-des-p{ 
  /* The image used */
  background-image: url("../assets/Inicio/principal.png");
  margin-top: 5%;
  height: 130%; 


  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.checkbox{
  margin-left: 10%;
}
.p-vendemas2{
  margin-left: 15%;
}
 


@-webkit-keyframes mover2 {
    1% { transform: translateX(1px); }
    50% { transform: translateY(-15px); }
}

@media only screen and (max-width: 770px) {
  .checkbox{
    width: 15px;
    margin-left: -1%;
}
  .p-vendemas{
    font-size: 1.2rem !important;
    width: 100%;
  }
  .p-vendemas2{
    font-size: 1.2rem !important;
    width: 100%;
    margin-top: -10%;
    width: 250px;
  }
  .text-titulo-vendemas{
  font-size: 1.6rem !important;
    text-align: center;
}
  .img-inmobina{
  display: none;
}
  .img-inmobina-movil{
  display: inline;
    width: 40%;
    margin-top: 5%;
    margin-bottom: 5%; 
    margin-left: 30%;
}
  .img-header-des-p-movil{
  display: inline;
    width: 40%;
    margin-top: 15%;
    margin-bottom: 10%;
    margin-left: 30%;
}
.img-header-des-p{
  display: none;
}
.text-titulo-header-des-p{
    color: rgb(255, 255, 255);
    font-size: 38px !important;
    width: 80%;
    margin-left: 2%;
    text-align: center;
    margin-left: 1.5rem;
  }
  .text-header-des-p{
    color: rgb(255, 255, 255);
    font-size: 20px !important;
    text-align: justify;
    padding: 5%;
    margin-top: -5%;
    
    
  }
  .text-des-p{
  font-size: 0.8rem !important;
  margin-top: 0px;
  margin-bottom: 50;
  margin-top: -5px;
}
.div-vendemas {
    margin-left: 5%;
    margin-right: 5%;
  }

}
@media only screen and (max-width: 1070px) and (min-width: 720px) {
  .text-titulo-header-des-p, .img-header-des-p{
    margin-top: 3rem !important;
    
  }
  
}

</style>